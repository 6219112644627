<template>
  <div v-if="isVisible" :style="styleObject" :class="classBlockObject" :id="properties.identifier || ''">
    <template v-if="type === 'group'">
        <v-container
          :fluid="!styles.hasColumnsStyle"
          :class="classGroupObject"
          class="py-0"
        >
          <slot></slot>
        </v-container>
    </template>
    <template v-if="type === 'block'">
      <v-container>
        <slot></slot>
      </v-container>
    </template>
  </div>
</template>

<script>
import i18n from "@/services/i18n";
export default {
  name: "mediligo-behaviour",
  i18n: i18n,
  props: ['state', 'identifier', 'behaviour', 'type', 'groupIdentifier', 'visibility'],
  data(){
    return {
      styles: {},
      parentStyles: {},
      properties: {},
      visibilityParsed: {}
    }
  },

  mounted() {
    this.visibilityParsed = JSON.parse(this.visibility)
    let behaviour = JSON.parse(this.behaviour)
    if (behaviour[this.identifier]){
      this.styles = behaviour[this.identifier].styles || {}
      this.properties = behaviour[this.identifier].properties || {}
    }
    if (this.type === 'block'){
      if (behaviour[this.groupIdentifier]){
        this.parentStyles = behaviour[this.groupIdentifier].styles || {}
      }
    }
  },

  computed: {
    styleObject(){
      let styles = {}
      if (this.styles){
        if (this.styles.hasBackgroundStyle){
          styles.backgroundColor = this.styles.backgroundColor.hexa
        }
        if (this.styles.hasBorderStyle){
          let width = this.styles.borderWidth || '2'
          styles.border = width + 'px solid ' + this.styles.borderColor.hexa
        }
      }
      return styles
    },
    classGroupObject(){
      let classes = ['pa-0']
      if (this.type === 'group'){
        if (this.styles){
          if (this.styles.hasColumnsStyle){
            let index = classes.indexOf('pa-0')
            if (index !== -1){ classes.splice(index, 1) }
            classes.push('d-flex flex-wrap')
            switch (this.styles.columnsJustify) {
              case 'start':
                classes.push('justify-start')
                break
              case 'center':
                classes.push('justify-center')
                break
              case 'end':
                classes.push('justify-end')
                break
              default:
                classes.push('justify-start')
            }
          }
        }
      }
      return classes
    },
    classBlockObject(){
      let classes = []
      if (this.type === 'block'){
        if (this.parentStyles){
          if (this.parentStyles.hasColumnsStyle){
            switch (this.parentStyles.columnsAmount) {
              case '2':
                classes.push('col-12 col-sm-6')
                break
              case '3':
                classes.push('col-12 col-sm-6 col-md-4')
                break
              case '4':
                classes.push('col-12 col-sm-6 col-md-3')
                break
              default:
                classes.push('col-12 col-sm-6 col-md-3')
            }
          }
        }
      }
      return classes
    },
    isVisible(){
      const visibilityRules = this.visibilityParsed ? this.visibilityParsed: {};

      if (! (this.identifier in visibilityRules)) {
        // i have no constraints, fine
        return true;
      }
      if (visibilityRules[this.identifier] === null) {
        //another case where there is nothing
        return true;
      }

      if (!this.state) {
        // no state, nothing to see
        return false;
      }

      const rules = visibilityRules[this.identifier].rules;
      for (let label in rules) {
        if (!(label in this.state.activities)){
          return false;
        }
        if (!this.state.activities[label].value[0]){
          return false
        }
      }

      // i'm doing everything right, we're good to go
      return true
    }
  },

  methods: {

  }
}
</script>

<style scoped>

</style>
