<template>
  <v-img
    v-if="image && image.type && image.type.includes('image/') && image.identifier"
    :width="image.style.width"
    height="100%"
    :class="classObject"
    :src="'/api/media/getFile/' + image.identifier + '?type=' + encodeURI(image.type)">
    <template v-slot:placeholder>
      <v-row class="fill-height ma-0" align="center" justify="center">
        <v-progress-circular color="primary" indeterminate></v-progress-circular>
      </v-row>
    </template>
  </v-img>
</template>

<script>

export default {
  name: 'mediligo-image',
  props: ['model'],
  data: function() {
    return {
      image: {
      }
    }
  },
  mounted() {
    this.image = this.model && this.model.length ? JSON.parse(this.model) : {};
  },
  computed: {
    classObject() {
      return {
        'float-none': this.image.style.float === 'none',
        'float-right': this.image.style.float === 'right',
        'float-left': this.image.style.float === 'left',
        'ma-2': this.image.style.float !== 'none'
      }
    },
  }
}
</script>
<style lang="scss">

</style>
