<template>
  <v-container>
    <div>
      <h3 v-if="loading">{{ $t('guide.savingResults') }}</h3>
      <h3 v-else>{{ $t('guide.savingResultsCompleted') }}</h3>
    </div>
  </v-container>
</template>

<script>
import i18n from "@/services/i18n";
import maxios from "@/services/mediligo-axios";

export default {
  i18n: i18n,
  props: ['state'],
  name: "guide-complete-survey",
  data() {
    return {
      loading: true
    }
  },
  mounted() {
    this.$i18n.locale = this.state.language;
    maxios.patch('/api/guide-survey/complete/', this.state.properties).then(() => {
      this.loading = false;
    });
  }
}
</script>

<style scoped>

</style>
