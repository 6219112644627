<template>
<span></span>
</template>

<script>
export default {
  name: "mediligo-page-input-reference"
}
</script>

<style scoped>

</style>
