<template>
  <v-container fluid class="accent px-0 py-12 white--text d-print-none">
    <v-container class="py-6">
      <div class="d-flex justify-space-between align-end flex-wrap">
        <h3>&copy; {{ $moment(Date.now()).format('YYYY') }}<!-- - {{state.company}} - <a :href="state.companyUrl">{{state.companyUrl}}</a>--></h3>
      </div>
    </v-container>
  </v-container>
</template>

<script>
import i18n from "@/services/i18n";

export default {
  name: "mediligo-footer",
  i18n: i18n,
  props: ['state'],
  data() {
    return {
      layout: {}
    }
  },

  methods: {

  },
  async mounted() {
  }
}
</script>

<style scoped>

</style>
