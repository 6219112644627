<template>
  <v-app>
    <v-app-bar app
               clipped-left
               :dark="state.portalSettings.dark"
               color="accent"
               class="d-print-none"
    >

<!--      <v-app-bar-nav-icon class="d-flex d-lg-none" @click="drawer = !drawer"></v-app-bar-nav-icon>-->

      <div class="d-flex justify-start align-center">
<!--        <v-img
          v-if="state.portalSettings && state.portalSettings.logoUrl"
          :src="state.portalSettings.logoUrl"
          height="54"
          max-width="200"
          contain
          class="mx-3 d-none d-lg-flex">
        </v-img>-->

        <v-img
          v-if="state.portalSettings && state.portalSettings.logoUrl"
          :src="state.portalSettings.logoUrl"
          height="54"
          max-width="200"
          contain
          class="mx-3 d-flex">
        </v-img>

        <v-divider vertical
                   class="mx-3">
        </v-divider>

        <v-toolbar-title class="mx-3">{{title}}</v-toolbar-title>
      </div>

      <v-spacer></v-spacer>

      <div class="d-flex justify-end align-center" v-if="activePage">
        <mediligo-locale-changer :active-page="activePage"></mediligo-locale-changer>
      </div>
<!--      <div class="d-flex justify-end align-center" v-if="auth.user">
        <mediligo-locale-changer :active-page="activePage" :current-user="auth.user"></mediligo-locale-changer>
        <v-menu open-on-hover
                offset-y
                tile>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark
              text
              v-bind="attrs"
              v-on="on">
              {{ auth.user.Name }}
              <v-icon right
                      dark
                      x-small>
                fa-chevron-down
              </v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>{{ auth.user.Name }}</v-list-item-title>
                <v-list-item-subtitle>{{ auth.user.Email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <template v-for="site in auth.sites">
              <v-list-item :key="site.Id" link :href="'/' + site.Id + '/page/list/templates'" :class="[ siteId === site.Id ? 'v-list-item&#45;&#45;active': '' ]">
                <v-list-item-content>
                  <v-list-item-title>{{ site.Name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider :key="'divider-' + site.Id"></v-divider>
            </template>
            <v-list-item link href="/logout">
              <v-list-item-content>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

      </div>-->
    </v-app-bar>
    <v-main :style="$vuetify.theme.dark ? '' : 'background-color: #F4F6F9;'">
      <mediligo-loader></mediligo-loader>
<!--      <mediligo-header :state="state"></mediligo-header>-->
      <slot></slot>
    </v-main>
<!--    <v-footer padless>
      <mediligo-footer :state="state"></mediligo-footer>
    </v-footer>-->
  </v-app>
</template>

<script>
import i18n from "@/services/i18n";
export default {
  i18n: i18n,
  name: "mediligo-layout",
  props: ['state', 'title', 'activePage'],

  data() {
    return {
    }
  },

  mounted() {
    let storage = localStorage.getItem('Lang')
    if (!storage){
      if (this.state.language){
        this.$i18n.locale = this.state.language
      } else {
        this.$i18n.locale = 'nl'
      }
    }
  },

  methods:{

  }
};
</script>

<style lang="scss">

/*.v-application {
  font-family:  'Fira Sans', sans-serif !important;
}*/

p {
  font-size: 1.2rem !important;
  line-height: 1.8rem !important;
  margin-bottom: 0 !important;
}

h1, h2 {
  margin-bottom: 12px !important;
}

.v-application {
  font-family:  'Poppins', sans-serif !important;
}
/*.v-main {
  background-color: #F4F6F9;
}*/

/*.v-list-item__title {
  color: #0D47A1;
}*/

.v-application--is-ltr .v-list-group--no-action > .v-list-group__items > .v-list-item {
  padding-left: 35px !important;
}

.editor-content-rendered p{
  margin-bottom: 1rem !important;
}

@media print {
  .v-main {
    background-color: #FFFFFF!important;
    padding: 0!important;
  }

  .theme--dark.v-divider {
    border-color: rgba(0,0,0,.12)!important;
  }

  .theme--dark.v-application {
    color: #000000!important;
  }

  .theme--dark.v-expansion-panels .v-expansion-panel,
  .theme--dark.v-list-item,
  .theme--dark.v-list-item .v-list-item__content,
  .theme--dark.v-list-item .v-list-item__title {
    background-color: #FFFFFF!important;
    color: #000000!important;
  }

  .v-expansion-panel-header__icon {
    display: none!important;
  }

  a[href^=http]:after {
    content:" <" attr(href) "> ";
  }

  @page {
    margin: 2cm;
  }
}
</style>
