<template>
    <span v-if="!loading && attachment">
        <template v-if="attachment.type.includes('application/pdf')">
            <span>{{ attachment.label }} <a target="_blank" :href="`/web/viewer.html?file=/api/media/getFile/${this.attachment.identifier}`"><v-icon color="primary" class="mx-1" small>fal fa-file-pdf</v-icon></a></span>
        </template>

        <template v-if="attachment.type.includes('image/')">
            <v-img :src="'/api/media/getFile/' + this.attachment.identifier + '?type=' + encodeURI(this.attachment.type)" class="my-2" contain height="350" />
        </template>

        <template v-if="attachment.type.includes('video/')">
            <video width="100%" height="350px" class="my-2" controls style="object-fit: contain;">
                <source :src="'/api/media/getFile/' + this.attachment.identifier + '?type=' + encodeURI(this.attachment.type)" :type="attachment.type">
              {{  $t('error.video-not-supported') }}
            </video>
        </template>
    </span>
</template>

<script>
 // import i18n from "@/services/i18n";
  export default {
   // i18n: i18n,
    name: 'mediligo-attachment-link',
    props: ['model', 'state'],
    data: function () {
        return {
            attachment: {},
            loading: true,
        }
    },
    computed: {
    },
    mounted() {
        this.attachment = this.model && this.model.length ? JSON.parse(this.model) : null
        this.loading = false
    },
    methods: {
    }
  }
</script>
<style lang="scss" scoped>
</style>

