<template>
  <div style="display: inline">
    <span v-if="!isLink">{{ value }}</span>
    <a v-if="isLink" :href="value" target="_blank">{{ description != null ? description : "Link" }}</a>
  </div>
</template>

<script>
import i18n from "@/services/i18n";

export default {
  i18n: i18n,
  name: "mediligo-smart-link",
  props: ['state', 'model'],
  data() {
    return {
      label: null,
      isLink: false,
      value: '-',
      renderedProperty: null,
      description: null
    }
  },
  watch: {
    state(newValue) {
      if (!this.label) {
        return;
      }

      const val = newValue.activities[this.label];
      if (!val){
        if (!this.value){
          this.value = '-'
        }
      }

      if (val){
        if (this.renderedProperty?.values){
          let renderedValue = ''
          this.renderedProperty.values.forEach(x => {
            if (x.type === 'moment'){
              renderedValue += this.$moment(val.value[0]).format(x.value)
            } else if (x.type === 'i18n') {
              renderedValue += this.$t(x.value).toString()
            } else if (x.type === 'string'){
              renderedValue += x.value
            } else if (x.type === 'url'){
              this.description = x.value
            }
          })
          this.value = renderedValue
        } else if (val.dateTimeFormat) {
          this.value = this.$moment(val.value[0]).format(val.dateTimeFormat)
        } else if (val.isTranslatable){
          this.value = this.$t(`options.fields.${val.value[0].toLowerCase()}`)
        } else if (val.isUrl){
          this.isLink = true
          this.value = val.value[0]
        }
        else {
          this.value = val.value[0]
        }
      }
    }
  },
  mounted() {
    if (this.state.language){
      this.$moment.locale(this.state.language)
    } else {
      this.$moment.locale('nl')
    }
    const parsedModel = this.model && this.model.length ? JSON.parse(this.model) : {
      label: 'unconfigured'
    };

    this.label = parsedModel.label;
    let val;

    if (parsedModel.value && parsedModel.value.length) {
      val = parsedModel
    } else {
      val = this.state.activities[this.label];
    }

    if (!val){ this.value = '-'}
    if (val){
      if (val.dateTimeFormat) {
        this.value = this.$moment(val.value[0]).format(val.dateTimeFormat)
      } else if (val.isTranslatable){
        this.value = this.$t(`options.fields.${val.value[0].toLowerCase()}`)
      } else if (val.isUrl){
        this.isLink = true
        this.value = val.value[0]
      }
      else {
        this.value = val.value[0]
      }
    }

    if (parsedModel["renderProperty"]){
      this.renderedProperty = parsedModel["renderProperty"]
    }
  }
}
</script>

<style scoped>

</style>
