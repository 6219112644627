<template>
  <span>
    <a v-if="!link.target_blank" :href="link.link">{{link.name}}</a>
    <a v-if="link.target_blank" target="_blank" :href="link.link">{{link.name}}</a>
  </span>
</template>

<script>
  export default {
    name: 'mediligo-standard-link',
    props: ['model'],
    data: function() {
      return {
        link: {}
      }
    },
    mounted: function() {
      this.link = this.model && this.model.length ? JSON.parse(this.model) : {};
    }
  }
</script>
<style lang="scss">
  .clickable {
    cursor: pointer !important;
  }
</style>
