import MediligoLayout from "@/layouts/MediligoLayout";
import IncludeMeLayout from "@/layouts/IncludeMeLayout.vue";
import VulnicareLayout  from "@/layouts/VulnicareLayout.vue";

const LayoutsPlugin = {
  install(Vue) {
    Vue.component(MediligoLayout.name, MediligoLayout)
    Vue.component(IncludeMeLayout.name, IncludeMeLayout)
    Vue.component(VulnicareLayout.name, VulnicareLayout)
  }
};

export { LayoutsPlugin }
