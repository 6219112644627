<template>
  <v-overlay :value="overlay" absolute class="loading-overlay">
    <div class="d-flex align-center" style="height: 100vh;">
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </div>
  </v-overlay>
</template>

<script>
export default {
  name: "mediligo-loader",
  data: function() {
    return {
      overlay: false
    }
  },

  mounted: function () {
    const that = this;
    this.$root.$on('mediligo-loader-show', async function () {
      that.overlay = true
    });

    this.$root.$on('mediligo-loader-hide', async function () {
      that.overlay = false
    });
  }
}
</script>

<style lang="scss">
  .loading-overlay{
    align-items: start;
  }
</style>
