<template>
  <v-container fluid class="pa-0">
    <div v-if="loading" class="d-flex justify-center my-3">
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </div>

    <div class="d-flex flex-wrap">

      <!--
          Patient
       -->
      <div class="pa-2 col-12 col-md-3">
        <v-card tile flat outlined class="mb-3">
          <v-card-title>{{ patient.firstName }} {{ patient.lastName }}</v-card-title>
        </v-card>
      </div>

      <div class="pa-2 col-12 col-md-9">
        <v-card tile flat outlined>
          <v-card-text class="d-flex justify-space-between align-center">
            <p>Sessies</p>
          </v-card-text>
        </v-card>
        <div class="d-flex flex-column overflow-y-auto pr-2" style="height: calc(100vh - 165px);">
          <v-timeline dense align-top>
            <v-slide-y-transition
              group
              hide-on-leave
            >
              <template v-for="(procedure, index) in procedures">

                <v-timeline-item
                  :key="'procedure-' + index"
                  color="primary"
                  fill-dot
                  small
                >
                  <v-card tile flat>
                    <div
                      :style="$vuetify.theme.dark ? 'border: solid ' + $vuetify.theme.themes.dark.primary + ' 2px;' : 'border: solid ' + $vuetify.theme.themes.light.primary + ' 2px;'">
                      <v-card-title class="d-flex justify-space-between">
                        <div>{{ $moment(procedure.performed).format('DD/MM/YYYY') }}</div>
                      </v-card-title>
                    </div>
                    <v-spacer></v-spacer>
                    <v-card-text>
                      <v-list>
                        <v-list-item>
                          <v-list-item-content>
                            <v-btn outlined tile @click.prevent="showPdf(procedure.identifier)">Toon volledig verslag</v-btn>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-btn elevation="0" tile @click.prevent="downloadPdf(procedure.identifier)">Download volledig verslag</v-btn>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-for="f in procedure.documents" :key="f.identifier">
                          <v-list-item-content>
                            <v-btn elevation="0" tile @click="downloadDoc(procedure.identifier, f)">Download {{ f.title }}</v-btn>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <v-list-item>
                        <v-list-item-content>
                          <v-btn elevation="0" tile @click="downloadDefault('vo2')">Download Algemeen advies (vo2 max)</v-btn>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-btn elevation="0" tile  @click="downloadDefault('stabilisatie')">Download Oefeningen Stabilisatie</v-btn>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
              </template>
            </v-slide-y-transition>
          </v-timeline>
        </div>
      </div>
    </div>

  </v-container>
</template>

<script>
import i18n from "@/services/i18n";
import api from "@/api/api";
import axios from '@/services/mediligo-axios'

export default {
  i18n: i18n,
  props: ['state'],
  name: "sportlab-dashboard",
  data() {
    return {
      loading: false,
      patient: {},
      procedures: [],
    }
  },
  computed: {},
  mounted() {
    this.$root.$on('set-lang', (lang) => {
      console.log('lang', this.$i18n.locale, lang)
      this.$i18n.locale = lang
      this.$moment.locale(lang)
    });
    this.getPatient()
  },
  methods: {
    async showPdf(p) {
      const pdf = await axios.get('/api/sportlab/pdf/' +p, {responseType: "blob"})
      const url = window.URL.createObjectURL(pdf.data);
      this.$nextTick(() => {
        // window.open('/web/viewer.html?file='+url, '_blank')
        window.open('/pdf-embed?slug=sportlab.' + p + '.pdf&url=' + url, '_blank')
      })
    },
    async downloadPdf(p) {
      const pdf = await axios.get('/api/sportlab/pdf/' + p, {responseType: "blob"})
      const url = window.URL.createObjectURL(pdf.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download','sportlab.' + p + '.pdf');
      document.body.appendChild(link);
      link.click();
    },
    async downloadDefault(f) {
      const doc =  await axios.get(`/api/sportlab/defaultDoc?file=${f}`, {responseType: "blob"})
      const url = window.URL.createObjectURL(doc.data);
      const link = document.createElement('a');
      link.href = url;
      var title = f === 'vo2' ? 'algemeen advies (vo2max).pdf' : 'oefeningen stabilisatie.pdf'
      link.setAttribute('download', title);
      document.body.appendChild(link);
      link.click();
    },
    async downloadDoc(p, f) {
      const doc = await axios.get(`/api/sportlab/doc/${p}/${f.identifier}`, {responseType: "blob"})
      const url = window.URL.createObjectURL(doc.data);
      const link = document.createElement('a');
      link.download= f.title;
      link.href = url;
      document.body.appendChild(link);
      console.log('filename is', link)
      link.click();
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    },
    getPatient() {
      const that = this;
      api.sportlab.getPatientById(this.state.patientId)
        .then((result) => {
          that.patient = result.data
          api.sportlab.getProcedures(that.state.patientId).then(
            (result) => {
              that.procedures = result.data;
            }
          )
        })
    }
  }
}
</script>

<style scoped>

</style>
