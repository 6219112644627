import SportlabHome from "@/sportlab/SportlabHome.vue";
import SportlabDashboard from "@/sportlab/SportlabDashboard.vue";


const SportlabPlugin = {
  install(Vue) {

    Vue.component(SportlabHome.name, SportlabHome)
    Vue.component(SportlabDashboard.name, SportlabDashboard)
  }
}

export { SportlabPlugin }
