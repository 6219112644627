<template>
  <v-select
    class="mx-1 rounded-0"
    color="primary"
    dense
    outlined
    style="width: 100px;"
    hide-details
    :items="languages"
    @change="languageChanged()"
    v-model="$i18n.locale"
    :menu-props="{ tile: true }">
  </v-select>
</template>

<script>
import i18n from "@/services/i18n";
import en from "vuetify/lib/locale/en"
import nl from "vuetify/lib/locale/nl"
import fr from "vuetify/lib/locale/fr"
export default {
  i18n: i18n,
  name: 'mediligo-locale-changer',
  props: ['activePage'],
  data: function () {
    return {
      languages: ['nl', 'fr', 'en'],
      shouldReloadPages: []
    }
  },

  mounted(){
    this.$vuetify.lang.locales = { en, nl, fr }
    this.init()
  },

  methods: {
    async init() {
      // Check for language parameter in url
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      if(urlParams.get('lang')){
        this.$i18n.locale = this.languages.includes(urlParams.get('lang')) ? urlParams.get('lang') : 'nl'
        this.$vuetify.lang.current = this.languages.includes(urlParams.get('lang')) ? urlParams.get('lang') : 'nl'
        this.$moment.locale(this.languages.includes(urlParams.get('lang')) ? urlParams.get('lang') : 'nl')
        localStorage.setItem('Lang', this.$i18n.locale)
        return
      }

      let storage = localStorage.getItem('Lang')

      if (storage && this.languages.includes(storage)){
        this.$i18n.locale = storage
        this.$vuetify.lang.current = storage
        this.$moment.locale(storage)
      }

      if (!storage){
        let navLang = navigator.language.substring(0,2)

        this.$i18n.locale = this.languages.includes(navLang) ? navLang : 'nl'
        this.$vuetify.lang.current = this.languages.includes(navLang) ? navLang : 'nl'
        this.$moment.locale(this.languages.includes(navLang) ? navLang : 'nl')
        localStorage.setItem('Lang', this.$i18n.locale)
      }
    },

    async languageChanged() {
      if (this.shouldReloadPages.includes(this.activePage)){
        await this.changeLanguage()
        window.location.reload()
      } else {
        await this.changeLanguage()
      }
      // this.$root.$emit('set-lang', this.$i18n.locale);
      // await this.changeLanguage()

    },

    async changeLanguage(){
      this.$moment.locale(this.$i18n.locale)
      this.$vuetify.lang.current = this.$i18n.locale
      localStorage.setItem('Lang', this.$i18n.locale)
      this.$root.$emit('set-lang', this.$i18n.locale);
    }
  }
}
</script>
