<template>

  <v-dialog
    transition="dialog-bottom-transition"
    max-width="900"
    :persistent="true"
    v-model="modalShow"
  >
    <template>
      <v-card tile>
        <v-card-title><v-icon small>fal fa-info</v-icon></v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-3">
          <div v-html="contentString"></div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end">
          <v-btn
            @click="hideModal('cancel')"
            tile
          >{{ $t("modal.cancel") }}</v-btn>
        </v-card-actions>
      </v-card>
    </template>

  </v-dialog>
</template>

<script>
  import i18n from "@/services/i18n";

  export default {
    i18n: i18n,
    name: 'mediligo-info-modal',
    props: [],
    data: function() {
      return {
        modalShow: false,
        content: '',
        contentArray: null,
          contentString: ""
      }
    },
    methods: {
      showModal: function() {
        this.modalShow=true;
      },
      hideModal: function() {
        this.modalShow=false;
      }
    },
    mounted: function(){
      const that = this;
      this.$root.$on('mediligo-info-modal-show',async function(value){
        that.modalShow=true;
        that.content = value.content;

        //convert each mediligo-standard-link to HTML. V-html does not load components.
        that.contentArray = [];
        if (that.content.includes("</mediligo-standard-link>")) {
          while (that.content.includes("</mediligo-standard-link>")) {
            that.contentArray.push(that.content.substring(0, that.content.indexOf("<mediligo-standard-link")));
            that.content = that.content.replace(that.contentArray[that.contentArray.length-1], "");
            that.contentArray.push(that.content.substring(0, (that.content.indexOf("</mediligo-standard-link>"))+("</mediligo-standard-link>".length)));
            that.content = that.content.replace(that.contentArray[that.contentArray.length-1], "");
          }
          that.contentString = "";
        } else {
          that.contentString = that.content;
        }

        that.contentArray.forEach(x => {
            if (x.startsWith("<mediligo-standard-link")){
                let model = x.substring(x.indexOf('model=\\"')+8, x.indexOf('\\"></mediligo-standard-link>'))
                model = model.replace(RegExp("&quot;", "g"), '"')
                const unwrapped_model = JSON.parse(model);

                if (unwrapped_model.target_blank !== "true") {
                    that.contentString +=
                        "<span>" +
                    "<a href=\"" + unwrapped_model.link + "\">" + unwrapped_model.name + "</a>" +
                    "</span>"
                } else {
                    that.contentString +=
                        "<span>" +
                        "<a target=\"_blank\" href=\"" + unwrapped_model.link + "\">" + unwrapped_model.name + "</a>" +
                        "</span>"
                }
            } else {
                that.contentString += x;
            }
        })
      });
    }
  }
</script>
