import axios from '@/services/mediligo-axios'

const mediligoConsent  = {
  handleConsent: (siteId, subjectId, consentId, data) => axios.post(`/api/consent/${siteId}/${subjectId}/${consentId}`, data),
}

const mediligoDashboard = {
  getActiveGuides: (siteId, lang) => axios.get(`/api/guide/${siteId}/${lang}`),
}

const ska = {
  getPatientInvite: (siteId, email, rrn) => axios.post(`/api/invite/send`, {email, rrn}, {
    headers: {
      "X-SITE-ID": siteId
    },
  }),
  getPatientById: (id) => axios.get(`/api/patient/${id}`),
  getRiskAssessments: (id) => axios.get(`/api/riskassessment/list?patientId=${id}`),
}


const sportlab = {
  getPatientInvite: (siteId, email, rrn) => axios.post(`/api/invite/send`,{email, rrn}, {
    headers: {
      "X-SITE-ID": siteId
    },
  }),
  getPatientChallengePhone: (siteId, email, phone) => axios.post(`/api/invite/challenge`, {email, phone}, {
    headers: {
      "X-SITE-ID": siteId
    },
  }),
  confirmPatientChallengePhone: (siteId, email, phone, activationCode) => axios.post(`/api/invite/send`,{email, activationCode, phone}, {
    headers: {
      "X-SITE-ID": siteId
    },
  }),
  getPatientById: (id) => axios.get(`/api/patient/${id}`),
  getProcedures: (id) => axios.get(`/api/sportlab/list?patientId=${id}`),
  getDocument: (docId) => axios.get(`/api/sportlab/document/${docId}`),
}

export default {
  mediligoConsent,
  mediligoDashboard,
  ska,
  sportlab
}
