import { VueMaskDirective } from 'v-mask'

import MediligoConsent from "@/components/MediligoConsent";
import MediligoHeader from "@/components/MediligoHeader";
import MediligoFooter from "@/components/MediligoFooter";
import MediligoSmartLink from "@/components/MediligoSmartLink";
import MediligoLoader from "@/components/MediligoLoader";
import MediligoInfoLink from "@/components/MediligoInfoLink";
import MediligoAttachmentLink from "@/components/MediligoAttachmentLink";
import MediligoInfoModal from "@/components/MediligoInfoModal";
import MediligoStandardLink from "@/components/MediligoStandardLink";
import MediligoPageInputReference from "@/components/MediligoPageInputReference";
import MediligoBehaviour from "@/components/MediligoBehaviour";
import MediligoButton from "@/components/MediligoButton";
import MediligoDashboard from "@/components/MediligoDashboard";
import MediligoImage from "@/components/MediligoImage";
import MediligoLocaleChanger from "@/components/MediligoLocaleChanger";
import MediligoContactBlock from "@/components/MediligoContactBlock";
import MediligoVisibility from "@/components/MediligoVisibility";

const MediligoPortalPlugin = {
  install(Vue) {
    Vue.directive('mask', VueMaskDirective);

    Vue.component(MediligoConsent.name, MediligoConsent)
    Vue.component(MediligoSmartLink.name, MediligoSmartLink)
    Vue.component(MediligoLoader.name, MediligoLoader)
    Vue.component(MediligoInfoLink.name, MediligoInfoLink)
    Vue.component(MediligoAttachmentLink.name, MediligoAttachmentLink)
    Vue.component(MediligoInfoModal.name, MediligoInfoModal)
    Vue.component(MediligoStandardLink.name, MediligoStandardLink)
    Vue.component(MediligoPageInputReference.name, MediligoPageInputReference)
    Vue.component(MediligoHeader.name, MediligoHeader)
    Vue.component(MediligoFooter.name, MediligoFooter)
    Vue.component(MediligoBehaviour.name, MediligoBehaviour)
    Vue.component(MediligoButton.name, MediligoButton)
    Vue.component(MediligoDashboard.name, MediligoDashboard)
    Vue.component(MediligoImage.name, MediligoImage)
    Vue.component(MediligoLocaleChanger.name, MediligoLocaleChanger)
    Vue.component(MediligoContactBlock.name, MediligoContactBlock)
    Vue.component(MediligoVisibility.name, MediligoVisibility)
  }
}

export { MediligoPortalPlugin }
