<template>
  <v-container fluid class="pa-0">
    <v-container>
      <v-alert
        v-if="!state.consent.approved"
        type="error"
        tile
        border="left"
        outlined
      >
        {{ $t('label.consent.notApproved') }} ({{ state.consent.lastUpdated | moment("DD MMM YYYY - HH:mm") }})
      </v-alert>
      <v-alert
        v-if="state.consent.approved"
        type="success"
        tile
        border="left"
        outlined
      >
        {{ $t('label.consent.approved') }} ({{ state.consent.lastUpdated | moment("DD MMM YYYY - HH:mm") }})
      </v-alert>
    </v-container>
    <div>
      <slot></slot>
    </div>
    <v-container>
      <div class="d-flex justify-center align-center">
        <v-btn
          v-on:click.prevent="leavePage()"
          class="ma-1 d-print-none"
          depressed
          tile
          outlined
          color="secondary"
        >
          {{ $t('buttons.leave')}}
        </v-btn>
        <v-btn
          v-on:click.prevent="handleConsent(false)"
          class="ma-1 d-print-none"
          depressed
          tile
          outlined
          color="error"
        >
          {{ $t('buttons.deny')}}
        </v-btn>
        <v-btn
          v-on:click.prevent="handleConsent(true)"
          class="ma-1 d-print-none"
          depressed
          tile
          color="primary"
        >
          {{ $t('buttons.accept')}}
        </v-btn>
      </div>
      <v-btn
        class="d-print-none"
        color="primary"
        fab
        dark
        bottom
        right
        fixed
        @click.prevent="print()"
      >
        <v-icon>far fa-print</v-icon>
      </v-btn>

      <v-expansion-panels accordion tile flat multiple class="rounded-0 mt-5" v-model="historyAccordion">
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('expansion-panel.header.consentHistory') }} -
            <span class="error--text mx-1" v-if="!state.consent.approved"> {{ $t('label.consent.notApproved') }} ({{ state.consent.lastUpdated | moment("DD MMM YYYY - HH:mm") }})</span>
            <span class="success--text mx-1" v-if="state.consent.approved"> {{ $t('label.consent.approved') }} ({{ state.consent.lastUpdated | moment("DD MMM YYYY - HH:mm") }})</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list-item
              v-for="(consent,i) in state.consentHistory"
              :key="i"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('label.consent.'+consent.approved) }} ({{ consent.lastUpdated | moment("DD MMM YYYY - HH:mm") }})
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </v-container>
</template>

<script>
import i18n from "@/services/i18n";
import api from "@/api/api";

export default {
  i18n: i18n,
  name: "mediligo-consent",
  props: ["state"],
  components: {
  },
  data() {
    return {
      isLoading: false,
      historyAccordion: [],
    }
  },
  mounted() {
    this.$root.$emit('mediligo-loader-hide')
  },

  computed: {
    hasDefaultSlot () {
      return !!this.$slots.default
    },
  },

  methods: {
    print(){
      window.print()
    },

    leavePage() {
      window.location.href = this.state.redirectUrl
    },

    async handleConsent(approved){
      let that = this
      let data = {
        approved: approved,
        pageInstanceId: this.state.pageInstanceId
      }

      await api.mediligoConsent.handleConsent(this.state.siteId, this.state.consent.subjectId, this.state.consent.id, data)
        .then(() => {
          if (that.state.redirectUrl?.length > 0) {
            that.$root.$emit('mediligo-loader-show')
            window.location.href = that.state.redirectUrl
          } else {
            that.$root.$emit('mediligo-loader-show')
            window.location.reload()
          }
        })
    },
  },
}

</script>

<style scoped>
p {
  font-size: 1.3rem !important;
}

@media print {
  .noprint {
    visibility: hidden;
  }
}
</style>
