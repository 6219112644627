<template>
  <span v-on:click="ping" class="clickable"><v-icon small color="primary">fal fa-info</v-icon></span>
</template>

<script>
  export default {
    name: 'mediligo-info-link',
    data: function() {
      return {
        content: ''
      }
    },
    methods: {
      ping: function() {
        this.$root.$emit("mediligo-info-modal-show", {content: this.content});
      }
    },
    mounted: function() {
      if (this.$attrs.model != null){
        this.content = this.$attrs.model.substring(1, this.$attrs.model.length-1);
      }
    }
  }
</script>
<style lang="scss">
  .clickable {
    cursor: pointer !important;
  }
</style>
