import SkaHome from '@/ska/SkaHome';
import SkaDashboard from "@/ska/SkaDashboard";

const SkaPlugin = {
  install(Vue) {
    Vue.component(SkaHome.name, SkaHome)
    Vue.component(SkaDashboard.name, SkaDashboard)
  }
}

export { SkaPlugin }
