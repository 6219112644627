<template>
  <div v-if="isVisible">
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'mediligo-visibility',
  props: ['visibility', 'identifier', 'state'],
  data: function () {
    return {

    }
  },
  computed: {
    isVisible: function() {
      const visibilityRules = this.visibility ? JSON.parse(this.visibility): {};
      if (! (this.identifier in visibilityRules)) {
        // i have no constraints, fine
        return true;
      }
      if (visibilityRules[this.identifier] === null) {
        //another case where there is nothing
        return true;
      }
      if (visibilityRules[this.identifier].externalinputs) {
        // show this, will be captured in v-show
        return true;
      }
      if (!this.state) {
        // no state, nothing to see
        return false;
      }
      let check = false;
      const rules = visibilityRules[this.identifier].rules;
      // console.log('checking', rules, this.identifier)
      if (this.identifier == '8aed99f9-60cb-4e4b-a62e-a9fef33a90d6') {
        console.log('checking', rules, this.identifier)
        Object.keys(this.state.activities).map(x => {console.log(x, this.state.activities[x]?.value)})
        check = true;
      }
      for (let label in rules) {
        //Check if the label is a pageLabel or a 'rulelabel' (Pagelabel comes from after the visibility update. Rulelabel from before)
        if (this.isPageLabel(label, rules)){
          if (check) console.log(0)
          for (let labelRule in rules[label]){
            if (!rules[label].value.length) {
              continue
            }
            //Check if the pagelabel is from this page.
            if (this.state.label === label){
              if (!(labelRule in this.state.activities)){
                if (check) console.log('1a', label)
                return false;
              }
              if (!rules[label][labelRule].every(x => this.state.activities[labelRule].value.includes(x))){
                if (check) console.log('2a', label)
                return false
              }
            }
            else {
              if (!this.state.activities[label+ "/" + labelRule].value || !rules[label][labelRule].every(x => this.state.activities[label+ "/" + labelRule].value.includes(x))) {
                if (check) console.log('3a', label)
                return false;
              }
            }
          }
        }
        else {
          //case 1: entry is present, and should not be present
          if (rules[label].includes("|mediligo_not_present|")) {
            if (!!this.state.activities && !!this.state.activities[label] && !!this.state.activities[label].value && this.state.activities[label].value.length > 0) {
              if (check) console.log('1', label)
              return false;
            } else {
              //nothing left to do
              if (check) console.log('2', label)
              continue;
            }
          }
          // i need at least something
          if (!(this.state.activities) || !(this.state.activities[label]) || !(this.state.activities[label].value) ||  this.state.activities[label].length === 0) {
            if (check) console.log('3', label)
            return false;
          }

          //case 2: entry is noy present, and should not be present
          if (rules[label].includes("|mediligo_present|")) {
            if (check) console.log('4', label)
            continue;
          }
          //case 3: entry is needed, but not present
          //case 4: entry is in the list
          if (this.state.activities[label]?.multiple) {
            //case: any required entries are present
            let condition = rules[label].any((x) => {
              if (check) console.log('4', label)
              return this.state.activities[label].value.includes(x)
            })
            if (condition === false) {
              if (check) console.log('5', label)
              return false
            }
          } else {
            //case: required entries is present
            if (!rules[label].includes(this.state.activities[label].value[0]) && rules[label].length > 0) {
              if (check) console.log('6', label)
              return false
            }
          }
        }
      }
      // i'm doing everything right, we're good to go
      if (check) console.log('7')
      return true;
    }
    // isVisible: function() {
    //   const visibilityRules = this.visibility ? JSON.parse(this.visibility): {};
    //   console.log(visibilityRules)
    //
    //   if (! (this.identifier in visibilityRules)) {
    //     // i have no constraints, fine
    //     return true;
    //   }
    //   if (visibilityRules[this.identifier] === null) {
    //     //another case where there is nothing
    //     return true;
    //   }
    //   if (visibilityRules[this.identifier].externalinputs) {
    //     //never show this
    //     return false;
    //   }
    //   if (!this.state) {
    //     // no state, nothing to see
    //     return false;
    //   }
    //   const rules = visibilityRules[this.identifier].rules;
    //   for (let label in rules) {
    //     //Check if the label is a pageLabel or a 'rulelabel' (Pagelabel comes from after the visibility update. Rulelabel from before)
    //     if (this.isPageLabel(label, rules)){
    //       for (let labelRule in rules[label]){
    //         //Check if the pagelabel is from this page. This is checked because
    //         if (this.state.label === label){
    //           if (!(labelRule in this.state.activities)){
    //             return false;
    //           }
    //           //If
    //           if (!rules[label][labelRule].every(x => this.state.activities[labelRule].value.includes(x))){
    //             return false
    //           }
    //         }
    //         else {
    //           if (!this.state.activities[label+ "/" + labelRule].value || !rules[label][labelRule].every(x => this.state.activities[label+ "/" + labelRule].value.includes(x))) {
    //             return false;
    //           }
    //         }
    //       }
    //     }
    //     else {
    //       if (!(label in this.state.activities)){
    //         return false;
    //       }
    //       if (this.state.activities[label].multiple){
    //         let condition = rules[label].every((x) => {
    //           return this.state.activities[label].value.includes(x)
    //         })
    //         if (condition === false){
    //           return false
    //         }
    //       } else {
    //         if (!rules[label].includes(this.state.activities[label].value[0])){
    //           return false
    //         }
    //       }
    //     }
    //   }
    //   // i'm doing everything right, we're good to go
    //   return true;
    // }
  },
  components: {
  },
  directives: {
  },
  methods: {
    isPageLabel: function(label, rules) {
      if (rules[label]){
        if (!Array.isArray(rules[label]) && typeof rules[label] === 'object'){
          return true
        }
      }
      return false
    }
  },
  mounted: function(){
  }
}
</script>
