<template>
  <v-container fluid>
    <div v-if="loading" class="d-flex justify-center my-3">
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </div>

    <div class="d-flex flex-column justify-center align-center">

      <div class="col-12 col-lg-6">
        <v-card tile flat>
          <v-card-title class="d-flex justify-center align-center text-center">
            {{ $t('cards.title.email') }}
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="email"
              :label="$t('label.email')"
              type="mail"
            ></v-text-field>
            <v-switch
              v-model="legalCountryBe"
              :label="$t('label.belgian-national')">
            </v-switch>
            <v-text-field :label="$t('label.nat-number')" v-model="natNum" v-if="!legalCountryBe"></v-text-field>
            <v-text-field :label="$t('label.nat-number')"
                          v-model="natNum"
                          v-else
                          v-mask="'##.##.##-###.##'"
                          :error = "!validRRN"
                          placeholder="yy.mm.dd-xxx.cd"
            >
            </v-text-field>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              :disabled="!email || !natNum || (!validRRN && legalCountryBe)"
              tile
              depressed
              color="primary"
              @click.prevent="sendInvite()"
            >
              {{ $t('buttons.send') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>

      <div class="col-12 col-lg-6" v-if="showInviteSend">
        <v-alert
          type="success"
          tile
          color="primary"
        >
          {{ $t('alerts.inviteSending') }}
        </v-alert>
      </div>

    </div>


  </v-container>
</template>

<script>
import i18n from "@/services/i18n";
import api from "@/api/api";

export default {
  i18n: i18n,
  props: ['state'],
  name: "ska-home",
  data() {
    return {
      loading: false,
      legalCountryBe: true,
      natNum: '',
      email: '',
      showInviteSend: false,
    }
  },
  computed: {
    validRRN() {
      const value = this.natNum;
      const validRRN = value != null  && value.length === 15;
      if (validRRN) {
        let numbers = value.replace(/\D/g,'');
        const rawValue = parseInt(numbers.substring(0,9),10);
        const rawControl = parseInt(numbers,10)-rawValue*100;
        let controlNumber = 97 - (rawValue % 97);
        if (controlNumber !== rawControl) {
          controlNumber = 97 - ( (rawValue+2000000000) % 97);
          if (controlNumber !== rawControl) {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.$root.$on('set-lang', (lang) => {
      console.log('lang', this.$i18n.locale, lang)
      this.$i18n.locale = lang
      this.$moment.locale(lang)
    });
    // this.$i18n.locale = this.state.language;
  },
  methods: {
    sendInvite(){
      api.ska.getPatientInvite(this.state.siteId, this.email, this.natNum.replace(/\D/g,''))
        .then(() => {
          this.showInviteSend = true
        })
    }
  }
}
</script>

<style scoped>

</style>
