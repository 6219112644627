<template>
  <v-container fluid>
    <div v-if="loading" class="d-flex justify-center my-3">
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </div>

    <div class="d-flex flex-column justify-center align-center">

      <div class="col-12 col-lg-6">
        <v-card-title class="d-flex justify-center align-center text-center">
          <small class="mr-3  text-gray-400 text-wrap-words" v-if="!showPhoneCode">{{ $t('label.sportlab.instructionsLogin') }}</small>
        </v-card-title>
        <v-card tile flat class="mt-4" v-if="!showPhoneCode">
        <v-card-text>
          <v-text-field
            v-model="email"
            :label="$t('label.email')"
            hide-details
            type="mail"
            :rules="requiredRule"
          ></v-text-field>
        </v-card-text>
        </v-card>
        <v-card tile flat class="mt-4" v-if="!showPhoneCode && (!phone || phone.length < 3)">

          <v-card-text>
            <div class="d-flex flex-row mt-3">
              <v-text-field :label="$t('label.nat-number')" v-model="natNum" v-if="!legalCountryBe"></v-text-field>
              <v-text-field :label="$t('label.nat-number')"
                            v-model="natNum"
                            v-else
                            v-mask="'##.##.##-###.##'"
                            :error="!validRRN"
                            placeholder="yy.mm.dd-xxx.cd"
              >
              </v-text-field>
              <v-switch
                v-model="legalCountryBe"
                :label="$t('label.belgian-national')">
              </v-switch>
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              :disabled="!email || !natNum || (!validRRN && legalCountryBe)"
              tile
              depressed
              @click.prevent="sendInvite()"
            >
              {{ $t('buttons.send') }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card tile flat class="mt-4" v-if="!showPhoneCode && (!natNum || natNum.length < 3)">
          <v-card-text>
            <div class="d-flex flex-row mt-3">
              <v-text-field :label="$t('label.phone')"
                            v-model="phone"
                            placeholder="+32 xxx xx xx xx"
              >
              </v-text-field>
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              :disabled="!phone || phone.length < 5"
              tile
              depressed
              @click.prevent="sendInvitePhone()"
            >
              {{ $t('buttons.send') }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card
          tile
          flat
          v-if="showPhoneCode"
          class="mt-4"
        >
          <v-card-title class="justify-center">
            {{ $t('label.enterCode') }}
          </v-card-title>
          <v-card-title class="d-flex justify-center align-center text-center">
            <small class="mr-3  text-gray-400 text-wrap-words">{{ $t('label.sportlab.instructionsLogin2') }}</small>
          </v-card-title>

          <v-divider></v-divider>
          <v-card-text class="w-100 align-center">
            <v-otp-input
              autofocus
              :disabled="loading"
              length="6"
              type="number"
              @finish="activate()"
              v-model="activationCode"
            ></v-otp-input>

          </v-card-text>
          <v-card-actions>
            <v-btn  :disabled="loading || showInviteSend" outlined tile @click.prevent="sendInvitePhone()">{{$t('label.resend')}}</v-btn>
            <v-btn :disabled="loading" outlined tile @click.prevent="showPhoneCode=false">{{$t('label.cancel')}}</v-btn>
          </v-card-actions>
        </v-card>
      </div>

      <div class="col-12 col-lg-6" v-if="showInviteSend">
        <v-alert
          type="success"
          tile
          color="primary"
        >
          {{ $t('alerts.inviteSending') }}
        </v-alert>
      </div>

    </div>


  </v-container>
</template>

<script>
import i18n from "@/services/i18n";
import api from "@/api/api";
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
const PNF = require('google-libphonenumber').PhoneNumberFormat;
export default {
  i18n: i18n,
  props: ['state'],
  name: "sportlab-home",
  data() {
    return {
      loading: false,
      legalCountryBe: true,
      natNum: '',
      showPhoneCode: false,
      email: '',
      activationCode: null,
      phone: null,
      showInviteSend: false,
      requiredRule: [
        v => !!v || 'Error'
      ],
    }
  },
  computed: {
    validRRN() {
      const value = this.natNum;
      const validRRN = value != null && value.length === 15;
      if (validRRN) {
        let numbers = value.replace(/\D/g, '');
        const rawValue = parseInt(numbers.substring(0, 9), 10);
        const rawControl = parseInt(numbers, 10) - rawValue * 100;
        let controlNumber = 97 - (rawValue % 97);
        if (controlNumber !== rawControl) {
          controlNumber = 97 - ((rawValue + 2000000000) % 97);
          if (controlNumber !== rawControl) {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.$root.$on('set-lang', (lang) => {
      console.log('lang', this.$i18n.locale, lang)
      this.$i18n.locale = lang
      this.$moment.locale(lang)
    });
    // this.$i18n.locale = this.state.language;
  },
  methods: {
    sendInvite() {
      api.sportlab.getPatientInvite(this.state.siteId, this.email, this.natNum.replace(/\D/g, ''))
        .then(() => {
          this.showInviteSend = true
        })
    },
    sendInvitePhone() {
      const parsedPhone = phoneUtil.parse(this.phone);
      this.phone = phoneUtil.format(parsedPhone, PNF.INTERNATIONAL);
      this.activationCode = null;

      api.sportlab.getPatientChallengePhone(this.state.siteId, this.email, this.phone)
        .then(() => {
          this.showPhoneCode = true;
        })

    },
    activate() {
      api.sportlab.confirmPatientChallengePhone(this.state.siteId, this.email, this.phone, this.activationCode)
        .then((res) => {
          if (res.data.result === "success") {
            this.showInviteSend = true;
          } else {
            this.activationCode= null;
          }
        })
    }
  }
}
</script>

<style scoped>
.text-gray-400 {
  color: #9e9e9e;
}
.text-wrap-words {
  white-space: normal;
  word-wrap: normal;
  word-break: initial;
}
.w-100 {
  width: 100%;
}
</style>
