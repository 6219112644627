import GuideCompleteSurvey from "@/guide/GuideCompleteSurvey";

const CuraeGuidePlugin = {
  install(Vue) {
    Vue.component(GuideCompleteSurvey.name, GuideCompleteSurvey)

  }
}

export { CuraeGuidePlugin }
