<template>
  <v-container fluid class="pa-0">

<!--      <div class="d-flex flex-wrap justify-space-between align-center px-2">
        <div>
          <h2 class="my-2">Dashboard</h2>
        </div>
      </div>-->

      <div class="d-flex flex-wrap-reverse">

        <div class="pa-2 col-9">
          <v-card tile flat outlined>
            <v-card-text class="d-flex justify-space-between align-center">
              <p>Guides for active careplans</p>

              <v-switch v-model="showFuture" label="show future" dense hide-details class="mt-0 pt-0"></v-switch>
              <v-switch v-model="showAlertError" label="show alert error" dense hide-details class="mt-0 pt-0"></v-switch>
              <v-switch v-model="showAlertWarning" label="show alert warning" dense hide-details class="mt-0 pt-0"></v-switch>
            </v-card-text>
          </v-card>
          <div class="d-flex flex-column overflow-y-auto pr-2" style="height: calc(100vh - 165px);">
            <v-timeline dense align-top>
              <v-slide-y-transition
                group
                hide-on-leave
              >
                <template v-for="block in showingGuides">

                  <!--
                    EDITOR MESSAGE
                  -->
                  <v-timeline-item
                    v-if="block.block.meta.component === 'mediligo-editor'"
                    :key="block.block.model.id"
                    :color="block.isFuture ? 'primary lighten-2' : 'primary'"
                    large
                  >
                    <v-card tile flat :elevation="hoverGuide === block.guideLabel ? '4' : '1'" :disabled="block.isFuture">
                      <v-card-title class="d-flex justify-space-between" :style="$vuetify.theme.dark ? 'border: solid ' + $vuetify.theme.themes.dark.primary + ' 2px;' : 'border: solid ' + $vuetify.theme.themes.light.primary + ' 2px;'">
                        <div>Info</div>
                        <div>{{ $moment(block.guideNotificationDate).format('DD/MM/YYYY') }}</div>
                      </v-card-title>
                      <v-spacer></v-spacer>
                      <v-card-text v-if="showAlertWarning">
                        <v-alert
                          dense
                          tile
                          text
                          class="mb-0"
                          type="warning"
                        >
                          I'm a dense alert with the <strong>outlined</strong> prop and a <strong>type</strong> of warning
                        </v-alert>
                      </v-card-text>
                      <v-card-text v-html="markedText(block.block.model.htmlContent.value)"></v-card-text>
                      <v-card-text class="text-end">
                        <v-chip
                          @mouseover="hoverGuide = block.guideLabel"
                          @mouseleave="hoverGuide = ''"
                          color="primary"
                          class="rounded-0"
                          outlined>
                          {{ block.guideTitle }}
                        </v-chip>
                      </v-card-text>
                    </v-card>
                  </v-timeline-item>

                  <!--
                    GUIDE SMS
                  -->
                  <v-timeline-item
                    v-else-if="block.block.meta.component === 'mediligo-guide-sms'"
                    :key="block.block.model.id"
                    :color="block.isFuture ? 'primary lighten-2' : 'primary'"
                    fill-dot
                    small
                  >
                    <v-card tile flat :disabled="block.isFuture" :elevation="hoverGuide === block.guideLabel ? '4' : ''">
                      <v-card-title class="d-flex justify-space-between" :style="$vuetify.theme.dark ? 'border: solid ' + $vuetify.theme.themes.dark.primary + ' 2px;' : 'border: solid ' + $vuetify.theme.themes.light.primary + ' 2px;'">
                        <div><v-icon color="primary">{{ block.block.meta.icon }}</v-icon></div>
                        <div>{{ $moment(block.guideNotificationDate).format('DD/MM/YYYY') }}</div>
                      </v-card-title>
                      <v-spacer></v-spacer>
                      <v-card-text v-if="showAlertError">
                        <v-alert
                          dense
                          tile
                          text
                          class="mb-0"
                          type="error"
                        >
                          I'm a dense alert with the <strong>outlined</strong> prop and a <strong>type</strong> of error
                        </v-alert>
                      </v-card-text>
                      <v-card-text v-html="markedText(block.block.model.htmlContent.value)"></v-card-text>
                      <v-card-text class="text-end">
                        <v-chip
                          @mouseover="hoverGuide = block.guideLabel"
                          @mouseleave="hoverGuide = ''"
                          color="primary"
                          class="rounded-0"
                          outlined>
                          {{ block.guideTitle }}
                        </v-chip>
                      </v-card-text>
                    </v-card>
                  </v-timeline-item>

                  <!--
                    OTHERS
                  -->
                  <v-timeline-item
                    v-else
                    :key="block.block.model.id"
                    :color="block.isFuture ? 'primary lighten-2' : 'primary'"
                    fill-dot
                    small
                  >
                    <v-card tile flat :disabled="block.isFuture" :elevation="hoverGuide === block.guideLabel ? '4' : ''">
                      <v-card-title class="d-flex justify-space-between" :style="$vuetify.theme.dark ? 'border: solid ' + $vuetify.theme.themes.dark.primary + ' 2px;' : 'border: solid ' + $vuetify.theme.themes.light.primary + ' 2px;'">
                        <div><v-icon color="primary" class="mr-2">{{ block.block.meta.icon }}</v-icon>{{ block.block.meta.component }}</div>
                        <div>{{ $moment(block.guideNotificationDate).format('DD/MM/YYYY') }}</div>
                      </v-card-title>
                      <v-spacer></v-spacer>
                      <v-card-text>{{ block.block.meta.component }}</v-card-text>
                      <v-card-text class="text-end">
                        <v-chip
                          @mouseover="hoverGuide = block.guideLabel"
                          @mouseleave="hoverGuide = ''"
                          color="primary"
                          class="rounded-0"
                          outlined>
                          {{ block.guideTitle }}
                        </v-chip>
                      </v-card-text>
                    </v-card>
                  </v-timeline-item>
                </template>
              </v-slide-y-transition>
            </v-timeline>
          </div>
        </div>

        <!--
            GUIDE LIST
         -->
        <div class="pa-2 col-3">
          <v-card tile flat outlined class="mb-3">
            <v-card-title>John Doe</v-card-title>
            <v-card-subtitle>15/12/1996</v-card-subtitle>
            <v-divider></v-divider>
            <v-card-text>
              <template v-for="(guide, index) in availableGuides">
                <div :key="'guide-' + index"><strong>{{ guide.title }}: </strong> {{ $moment(guide.date).format('DD/MM/YYYY') }}</div>
              </template>
            </v-card-text>
          </v-card>
          <v-card tile flat outlined>
            <v-card-text>
              <v-list shaped>
                <v-list-item-group
                  v-model="selectedGuides"
                  multiple
                  color="primary"
                >
                  <v-list-item
                    v-for="(guide, i) in availableGuides"
                    :key="`item-${i}`"
                    :value="guide.label"
                    class="my-1"
                  >
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title v-text="guide.title"></v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-checkbox
                          :input-value="active"
                        ></v-checkbox>
                      </v-list-item-action>
                    </template>
                  </v-list-item>

                </v-list-item-group>
              </v-list>
            </v-card-text>
          </v-card>
        </div>
      </div>

  </v-container>
</template>

<script>
import i18n from "@/services/i18n";
// import api from "@/api/api";
const marked = require('marked');

export default {
  i18n: i18n,
  name: "mediligo-dashboard",
  props: ["state"],
  components: {
  },
  data() {
    return {
      isLoading: false,
      availableGuides: [
        { label: 'first_guide', title: 'First guide', date: Date.now() },
        { label: 'second_guide', title: 'Second guide', date: this.$moment(Date.now()).add(2 ,'d') }
      ],
      selectedGuides: [],
      guides: [
        {
          "blocks": [
            {
              "blockGroupIdentifier": "bf9fdb8f-c65c-4a2d-b063-1ea71c94f9d2",
              "block": {
                "isActive": false,
                "model": {
                  "$type": "Mediligo.Core.Models.Content.MediligoEditorBlock, Mediligo.Core.Models",
                  "htmlContent": {
                    "value": "<p class=\"clearfix\">min 45 dagen</p>"
                  },
                  "model": {
                    "value": null
                  },
                  "id": "922793f0-6d64-421b-9b7a-687a48932fee",
                  "type": "Mediligo.Core.Models.Content.MediligoEditorBlock"
                },
                "meta": {
                  "isGroup": false,
                  "isCollapsed": false,
                  "isReadonly": false,
                  "showHeader": true,
                  "uid": "uid-324009837",
                  "name": "block.content",
                  "title": "",
                  "icon": "fas fa-flux-capacitor",
                  "description": null,
                  "placeholder": null,
                  "component": "mediligo-editor"
                }
              },
              "time": {
                "years": 0,
                "months": 0,
                "days": "-45",
                "hours": 0,
                "active": "days",
                "totalInHours": -1080
              }
            },
            {
              "blockGroupIdentifier": "bf9fdb8f-c65c-4a2d-b063-1ea71c94f9d2",
              "block": {
                "isActive": false,
                "model": {
                  "$type": "Mediligo.Core.Models.Guide.MediligoEmailBlock, Mediligo.Core.Models",
                  "htmlContent": {
                    "value": null
                  },
                  "model": {
                    "value": "{\"page\":\"a9546840-8097-4871-ae5f-1474efb1accb\"}"
                  },
                  "id": "91bbda1c-36ce-4033-a75d-f2a549cb772c",
                  "type": "Mediligo.Core.Models.Guide.MediligoEmailBlock"
                },
                "meta": {
                  "isGroup": false,
                  "isCollapsed": false,
                  "isReadonly": false,
                  "showHeader": true,
                  "uid": "uid-928963465",
                  "name": "block.guide.email",
                  "title": "",
                  "icon": "fa-solid fa-at",
                  "description": null,
                  "placeholder": null,
                  "component": "mediligo-guide-email"
                }
              },
              "time": {
                "years": 0,
                "months": "-1",
                "days": 0,
                "hours": 0,
                "active": "months",
                "totalInHours": -744
              }
            },
            {
              "blockGroupIdentifier": "bf9fdb8f-c65c-4a2d-b063-1ea71c94f9d2",
              "block": {
                "isActive": false,
                "model": {
                  "$type": "Mediligo.Core.Models.Guide.MediligoQuestBlock, Mediligo.Core.Models",
                  "htmlContent": {
                    "value": null
                  },
                  "model": {
                    "value": "{\"questKey\":\"abc-def\",\"questLabel\":\"questje\"}"
                  },
                  "id": "d835283e-c8bd-4da4-8fb2-c791c546accb",
                  "type": "Mediligo.Core.Models.Guide.MediligoQuestBlock"
                },
                "meta": {
                  "isGroup": false,
                  "isCollapsed": false,
                  "isReadonly": false,
                  "showHeader": true,
                  "uid": "uid-41816801",
                  "name": "block.guide.quest",
                  "title": "",
                  "icon": "fa-solid fa-message-question",
                  "description": null,
                  "placeholder": null,
                  "component": "mediligo-guide-quest"
                }
              },
              "time": {
                "years": 0,
                "months": 0,
                "days": 0,
                "hours": 0,
                "active": "days",
                "totalInHours": 0
              }
            },
            {
              "blockGroupIdentifier": "bf9fdb8f-c65c-4a2d-b063-1ea71c94f9d2",
              "block": {
                "isActive": false,
                "model": {
                  "$type": "Mediligo.Core.Models.Guide.MediligoSmsBlock, Mediligo.Core.Models",
                  "htmlContent": {
                    "value": "<p class=\"clearfix\">ola enzo</p>"
                  },
                  "model": {
                    "value": null
                  },
                  "id": "2ce1f772-256d-43a9-9393-a3bf61c02a07",
                  "type": "Mediligo.Core.Models.Guide.MediligoSmsBlock"
                },
                "meta": {
                  "isGroup": false,
                  "isCollapsed": false,
                  "isReadonly": false,
                  "showHeader": true,
                  "uid": "uid-333178814",
                  "name": "block.guide.sms",
                  "title": "",
                  "icon": "fa-solid fa-sms",
                  "description": null,
                  "placeholder": null,
                  "component": "mediligo-guide-sms"
                }
              },
              "time": {
                "years": 0,
                "months": 0,
                "days": "5",
                "hours": 0,
                "active": "days",
                "totalInHours": 120
              }
            }
          ],
          "lastModified": "0001-01-01T00:00:00",
          "pageId": "0c363c45-ac23-4c1e-9043-1d226c923196",
          "instanceId": "7b9e2a2d-bd62-4b6b-af09-ebc7b3a819d8",
          "published": "2022-02-09T13:23:56.656929",
          "instanceSettings": "{\"title\":\"First Guide\"}",
          "pageName": "First guide",
          "pageType": "guide",
          "language": "en",
          "label": "first_guide",
          "lastUpdatedOn": "2022-02-09T13:23:56.646455"
        },
        {
          "blocks": [
            {
              "blockGroupIdentifier": "4c52991c-2554-4151-afc1-77a7b23835b3",
              "block": {
                "isActive": false,
                "model": {
                  "$type": "Mediligo.Core.Models.Content.MediligoEditorBlock, Mediligo.Core.Models",
                  "htmlContent": {
                    "value": "<p class=\"clearfix\">Dit is wat info</p>"
                  },
                  "model": {
                    "value": null
                  },
                  "id": "fe5a09fb-1005-46bf-9eaa-49dc8ae36a07",
                  "type": "Mediligo.Core.Models.Content.MediligoEditorBlock"
                },
                "meta": {
                  "isGroup": false,
                  "isCollapsed": false,
                  "isReadonly": false,
                  "showHeader": true,
                  "uid": "uid-1535162076",
                  "name": "block.content",
                  "title": "",
                  "icon": "fas fa-flux-capacitor",
                  "description": null,
                  "placeholder": null,
                  "component": "mediligo-editor"
                }
              },
              "time": {
                "years": 0,
                "months": 0,
                "days": "-10",
                "hours": 0,
                "active": "days",
                "totalInHours": -240
              }
            },
            {
              "blockGroupIdentifier": "4c52991c-2554-4151-afc1-77a7b23835b3",
              "block": {
                "isActive": false,
                "model": {
                  "$type": "Mediligo.Core.Models.Content.MediligoEditorBlock, Mediligo.Core.Models",
                  "htmlContent": {
                    "value": "<p class=\"clearfix\">nog extra info</p>"
                  },
                  "model": {
                    "value": null
                  },
                  "id": "847f1d39-549f-4c01-8d74-0ed8406475f4",
                  "type": "Mediligo.Core.Models.Content.MediligoEditorBlock"
                },
                "meta": {
                  "isGroup": false,
                  "isCollapsed": false,
                  "isReadonly": false,
                  "showHeader": true,
                  "uid": "uid-998004097",
                  "name": "block.content",
                  "title": "",
                  "icon": "fas fa-flux-capacitor",
                  "description": null,
                  "placeholder": null,
                  "component": "mediligo-editor"
                }
              },
              "time": {
                "years": 0,
                "months": 0,
                "days": "-5",
                "hours": 0,
                "active": "days",
                "totalInHours": -120
              }
            },
            {
              "blockGroupIdentifier": "4c52991c-2554-4151-afc1-77a7b23835b3",
              "block": {
                "isActive": false,
                "model": {
                  "$type": "Mediligo.Core.Models.Guide.MediligoQuestBlock, Mediligo.Core.Models",
                  "htmlContent": {
                    "value": null
                  },
                  "model": {
                    "value": "{\"questKey\":\"dd\",\"questLabel\":\"dd\"}"
                  },
                  "id": "ad8c656e-8066-4db6-a59a-12e29db88282",
                  "type": "Mediligo.Core.Models.Guide.MediligoQuestBlock"
                },
                "meta": {
                  "isGroup": false,
                  "isCollapsed": false,
                  "isReadonly": false,
                  "showHeader": true,
                  "uid": "uid-990903400",
                  "name": "block.guide.quest",
                  "title": "",
                  "icon": "fa-solid fa-message-question",
                  "description": null,
                  "placeholder": null,
                  "component": "mediligo-guide-quest"
                }
              },
              "time": {
                "years": 0,
                "months": 0,
                "days": "-4",
                "hours": 0,
                "active": "days",
                "totalInHours": -96
              }
            },
            {
              "blockGroupIdentifier": "4c52991c-2554-4151-afc1-77a7b23835b3",
              "block": {
                "isActive": false,
                "model": {
                  "$type": "Mediligo.Core.Models.Guide.MediligoSmsBlock, Mediligo.Core.Models",
                  "htmlContent": {
                    "value": "<p class=\"clearfix\">sms reminder</p>"
                  },
                  "model": {
                    "value": null
                  },
                  "id": "67221131-7582-43a4-bdac-5d6cfc576b2e",
                  "type": "Mediligo.Core.Models.Guide.MediligoSmsBlock"
                },
                "meta": {
                  "isGroup": false,
                  "isCollapsed": false,
                  "isReadonly": false,
                  "showHeader": true,
                  "uid": "uid-239412389",
                  "name": "block.guide.sms",
                  "title": "",
                  "icon": "fa-solid fa-sms",
                  "description": null,
                  "placeholder": null,
                  "component": "mediligo-guide-sms"
                }
              },
              "time": {
                "years": 0,
                "months": 0,
                "days": "-1",
                "hours": 0,
                "active": "days",
                "totalInHours": -24
              }
            },
            {
              "blockGroupIdentifier": "4c52991c-2554-4151-afc1-77a7b23835b3",
              "block": {
                "isActive": false,
                "model": {
                  "$type": "Mediligo.Core.Models.Content.MediligoEditorBlock, Mediligo.Core.Models",
                  "htmlContent": {
                    "value": "<p class=\"clearfix\">Dag van operatie</p>"
                  },
                  "model": {
                    "value": null
                  },
                  "id": "6216e927-8064-48bb-aaad-9a7160d98f94",
                  "type": "Mediligo.Core.Models.Content.MediligoEditorBlock"
                },
                "meta": {
                  "isGroup": false,
                  "isCollapsed": false,
                  "isReadonly": false,
                  "showHeader": true,
                  "uid": "uid-1989233978",
                  "name": "block.content",
                  "title": "",
                  "icon": "fas fa-flux-capacitor",
                  "description": null,
                  "placeholder": null,
                  "component": "mediligo-editor"
                }
              },
              "time": {
                "years": 0,
                "months": 0,
                "days": 0,
                "hours": 0,
                "active": "days",
                "totalInHours": 0
              }
            },
            {
              "blockGroupIdentifier": "4c52991c-2554-4151-afc1-77a7b23835b3",
              "block": {
                "isActive": false,
                "model": {
                  "$type": "Mediligo.Core.Models.Guide.MediligoEmailBlock, Mediligo.Core.Models",
                  "htmlContent": {
                    "value": null
                  },
                  "model": {
                    "value": "{\"value\":{}}"
                  },
                  "id": "b5c5c309-4c84-499b-b53f-ee49b8f1f7e4",
                  "type": "Mediligo.Core.Models.Guide.MediligoEmailBlock"
                },
                "meta": {
                  "isGroup": false,
                  "isCollapsed": false,
                  "isReadonly": false,
                  "showHeader": true,
                  "uid": "uid-1986946188",
                  "name": "block.guide.email",
                  "title": "",
                  "icon": "fa-solid fa-at",
                  "description": null,
                  "placeholder": null,
                  "component": "mediligo-guide-email"
                }
              },
              "time": {
                "years": 0,
                "months": 0,
                "days": 0,
                "hours": 0,
                "active": "days",
                "totalInHours": 0
              }
            },
            {
              "blockGroupIdentifier": "4c52991c-2554-4151-afc1-77a7b23835b3",
              "block": {
                "isActive": false,
                "model": {
                  "$type": "Mediligo.Core.Models.Guide.MediligoSmsBlock, Mediligo.Core.Models",
                  "htmlContent": {
                    "value": "<p class=\"clearfix\">sms after</p>"
                  },
                  "model": {
                    "value": null
                  },
                  "id": "6d8f298d-1bfa-47e1-bf20-6962e9c2134a",
                  "type": "Mediligo.Core.Models.Guide.MediligoSmsBlock"
                },
                "meta": {
                  "isGroup": false,
                  "isCollapsed": false,
                  "isReadonly": false,
                  "showHeader": true,
                  "uid": "uid-558675782",
                  "name": "block.guide.sms",
                  "title": "",
                  "icon": "fa-solid fa-sms",
                  "description": null,
                  "placeholder": null,
                  "component": "mediligo-guide-sms"
                }
              },
              "time": {
                "years": 0,
                "months": 0,
                "days": "1",
                "hours": 0,
                "active": "days",
                "totalInHours": 24
              }
            }
          ],
          "lastModified": "0001-01-01T00:00:00",
          "pageId": "82d08892-74b7-40ee-be95-14dd57df3cca",
          "instanceId": "bdd714ae-ce46-47c9-9953-8ceeb637ee8f",
          "published": "2022-02-17T14:54:31.096231",
          "instanceSettings": "{\"title\":\"Second guide\"}",
          "pageName": "Second guide",
          "pageType": "guide",
          "language": "en",
          "label": "second_guide",
          "lastUpdatedOn": "2022-02-17T14:54:31.066072"
        }
      ],
      showFuture: false,
      showAlertError: false,
      showAlertWarning: false,
      hoverGuide: ''
    }
  },
  mounted() {
    this.selectedGuides = ['first_guide', 'second_guide']
  },

  computed: {
    showingGuides(){
      let guideBlocks = []

      let filteredGuides = this.guides.filter((x) => { return this.selectedGuides.includes(x.label)})

      filteredGuides.forEach((guide) => {
        guide.blocks.forEach((block) => {
          let blockWithExtra = block
          blockWithExtra.guideLabel = guide.label
          blockWithExtra.guideTitle = this.availableGuides.find((x) => { return x.label === guide.label }).title

          let date = this.availableGuides.find((x) => { return x.label === guide.label }).date
          let guideNotificationDate = this.$moment(date).add(block.time.totalInHours, 'h')
          blockWithExtra.guideNotificationDate = guideNotificationDate
          blockWithExtra.isFuture = !this.$moment(Date.now()).isSameOrAfter(guideNotificationDate)

          if (this.showFuture || !blockWithExtra.isFuture){
            guideBlocks.push(blockWithExtra)
          }
        })
      })

      guideBlocks.sort((a, b) => {
        // return (b.time.totalInHours - a.time.totalInHours)
        return (this.$moment(b.guideNotificationDate).unix() - this.$moment(a.guideNotificationDate).unix())
      })

      return guideBlocks
    }
  },

  methods: {
    markedText(text){
      if (!text) return '';
      return marked(text)
    },
  },
}

</script>

<style scoped>
</style>
