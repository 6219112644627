<template>
  <v-container fluid class="primary px-0">
    <v-container >
      <div class="d-flex justify-space-between align-center flex-wrap">
        <img :src="state.logoUri" style="max-height: 100px; object-fit: contain;"/>
        <v-menu offset-y tile v-if="state.language">
          <template v-slot:activator="{ on, attrs, value }">
            <h3
              class="clickable text-uppercase white--text d-print-none"
              v-bind="attrs"
              v-on="on"
            >
              {{ state.language }}
              <v-scroll-y-transition leave-absolute hide-on-leave>
                <v-icon v-if="value" color="white" class="ml-1" x-small key="chevron-up">fal fa-chevron-up</v-icon>
                <v-icon v-if="!value" color="white" class="ml-1" x-small key="chevron-down">fal fa-chevron-down</v-icon>
              </v-scroll-y-transition>
            </h3>
          </template>
          <v-list color="primary">
            <v-list-item v-if="state.language !== 'en'" link @click.prevent="changeLanguage('en')">
              <v-list-item-title class="white--text"><h3>EN</h3></v-list-item-title>
            </v-list-item>
            <v-list-item v-if="state.language !== 'fr'" link @click.prevent="changeLanguage('fr')">
              <v-list-item-title class="white--text"><h3>FR</h3></v-list-item-title>
            </v-list-item>
            <v-list-item v-if="state.language !== 'nl'" link @click.prevent="changeLanguage('nl')">
              <v-list-item-title class="white--text"><h3>NL</h3></v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-container>
  </v-container>
</template>

<script>
import i18n from "@/services/i18n";

export default {
  name: "mediligo-header",
  i18n: i18n,
  props: ['state', 'page'],
  data() {
    return {
      layout: {},
      tagLines: {},
      tagLine: null
    }
  },
  async mounted() {
  },
  methods: {
    changeLanguage(lang){
      if (this.state.requireReload) {
        this.$root.$emit('mediligo-loader-show')
        window.location.href = '/consent/' + lang + '/' + this.state.jwt
      }
    }
  }
}
</script>

<style scoped>

</style>
