<template>
  <div>
    <v-btn
      tile
      block
      color="accent"
      @click="$vuetify.goTo('#' + buttonModel.sectionIdentifier)"
    >
      {{ buttonModel.buttonText }}
    </v-btn>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "mediligo-button",
  props: ['state', 'model'],
  data() {
    return {
      buttonModel: {}
    }
  },
  mounted() {
    this.buttonModel = JSON.parse(this.model)
  }
}
</script>

<style scoped>

</style>
