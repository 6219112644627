<template>
  <v-container fluid class="pa-0">
    <slot></slot>

    <v-btn
      class="d-print-none"
      color="accent"
      fab
      dark
      bottom
      right
      fixed
      @click.prevent="print()"
    >
      <v-icon>far fa-print</v-icon>
    </v-btn>
  </v-container>
<!--
  <v-btn fab dark bottom right fixed class="no-print" @click="print()" primary>{{$t('buttons.print')}}</v-btn>
-->
</template>

<script>
export default {
  name: "vulnicare-render-page",
  methods: {
    print() {
      window.print()
    }
  }
}
</script>

<style scoped>
@media print
{
  .no-print, .no-print *
  {
    display: none !important;
  }
}
</style>
