import VulnicareRenderPage from "@/vulnicare/VulnicareRenderPage";

const VulnicarePlugin = {
  install(Vue) {
    Vue.component(VulnicareRenderPage.name, VulnicareRenderPage)
  }
}

export { VulnicarePlugin }

