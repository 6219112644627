<template>
  <div>
    <iframe
      v-if="configuration"
      width="600"
      height="450"
      style="border:0"
      loading="lazy"
      allowfullscreen
      referrerpolicy="no-referrer-when-downgrade"
      :src="googleMapsUrl">
    </iframe>
  </div>
</template>

<script>
import i18n from "@/services/i18n";

export default {
  i18n: i18n,
  name: "mediligo-contact-block",
  props: ['model', 'shared-state', 'google-maps-key'],
  data() {
    return {
      configuration: ''
    }
  },
  computed: {
    googleMapsUrl() {
      return `https://www.google.com/maps/embed/v1/place?key=${this.googleMapsKey}&q=${this.configuration}`
    }
  },
  async mounted() {
    if (!this.model || !this.model.length) {
      this.configuration = "<invalid configuration>";
      return;
    }
    const tmp = JSON.parse(this.model);
    this.configuration = tmp.configuration
  },
  methods: {
    activateAction() {
      window.open(`/vulnicare/referral/${this.pageId}`, '_blank').focus();
    }
  },
}
</script>

<style scoped>

</style>
