<template>
  <v-container fluid class="pa-0">
    <div v-if="loading" class="d-flex justify-center my-3">
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </div>

    <div class="d-flex flex-wrap">

      <!--
          Patient
       -->
      <div class="pa-2 col-12 col-md-3">
        <v-card tile flat outlined class="mb-3">
          <v-card-title>{{ patient.firstName }} {{ patient.lastName }}</v-card-title>
        </v-card>
      </div>

      <div class="pa-2 col-12 col-md-9">
        <v-card tile flat outlined>
          <v-card-text class="d-flex justify-space-between align-center">
            <p>{{$t('label.examinations')}}</p>
          </v-card-text>
        </v-card>
        <div class="d-flex flex-column overflow-y-auto pr-2" style="height: calc(100vh - 165px);">
          <v-timeline dense align-top>
            <v-slide-y-transition
              group
              hide-on-leave
            >
              <template v-for="(assessment, index) in riskAssessments">

                <!--
                  GUIDE SMS
                -->
                <v-timeline-item
                  :key="'riskassessment-' + index"
                  color="primary"
                  fill-dot
                  small
                >
                  <v-card tile flat>
                    <div :style="$vuetify.theme.dark ? 'border: solid ' + $vuetify.theme.themes.dark.primary + ' 2px;' : 'border: solid ' + $vuetify.theme.themes.light.primary + ' 2px;'">
                      <v-card-title class="d-flex justify-space-between">
                        <!--<div><v-icon color="primary">{{ block.block.meta.icon }}</v-icon></div>
                        <div>{{ $moment(block.guideNotificationDate).format('DD/MM/YYYY') }}</div>-->
                        <div>{{ getSport(assessment.reasons) }}</div>
                        <div class="body-1">{{ $t('ska.risks.' + assessment.risk) }}</div>
                        <!--<div class="body-1">{{ $moment(assessment.startDate).format('DD/MM/YYYY') }} - {{ $moment(assessment.endDate).format('DD/MM/YYYY') }}</div>-->
                      </v-card-title>
                      <v-card-subtitle>
                        <div>{{ $moment(assessment.startDate).format('DD/MM/YYYY') }} - {{ $moment(assessment.endDate).format('DD/MM/YYYY') }}</div>
                      </v-card-subtitle>
                    </div>
                    <v-spacer></v-spacer>
                    <v-card-text>
                      <div><strong>{{$t('label.by')}}: </strong>{{ assessment.performer.lastName }}</div>
                      <!--                      <div><strong>Valid for: </strong>{{ assessment.validityInMonths }} months</div>-->
                      <div><strong>{{$t('label.start')}}: </strong>{{ $moment(assessment.startDate).format('DD/MM/YYYY') }}</div>
                      <div><strong>{{$t('label.validUntil')}}: </strong>{{ $moment(assessment.endDate).format('DD/MM/YYYY') }}</div>
                      <div><strong>{{$t('label.forwardedTo')}}: {{getFederation(assessment.reasons)}}</strong></div>
                    </v-card-text>
                    <v-card-text class="text-end">
                      <v-btn
                        :disabled="!assessment.mitigation"
                        color="primary"
                        tile
                        depressed
                        @click.prevent="showExercises(assessment.mitigation)"
                        class="mr-2"
                      >
                        {{ $t('buttons.exercises') }}
                      </v-btn>
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
              </template>
            </v-slide-y-transition>
          </v-timeline>
        </div>
      </div>
    </div>

  </v-container>
</template>

<script>
import i18n from "@/services/i18n";
import api from "@/api/api";
import skaSports from './skaSports.json';

export default {
  i18n: i18n,
  props: ['state'],
  name: "ska-dashboard",
  data() {
    return {
      loading: false,
      patient: {},
      riskAssessments: [],
      fedList : {
        "https://ska.fhir.mediligo.com/federation|FED_VENTOUX": "Sporta-Mont Ventoux",
        "https://ska.fhir.mediligo.com/federation|FED_KBVB": "Voetbal Vlaanderen-RBFA-ACFF",
        "https://ska.fhir.mediligo.com/federation|FED_CYCLVL": "Cycling Vlaanderen",
        "https://ska.fhir.mediligo.com/federation|FED_VLTRIDU": "Vlaamse Triatlon en Duatlon Liga",
        "https://ska.fhir.mediligo.com/federation|FED_VLVOL": "Volley Vlaanderen",
        "https://ska.fhir.mediligo.com/federation|FED_KOTK": "KOTK event"
      }
    }
  },
  computed: {
  },
  mounted() {
    this.$root.$on('set-lang', (lang) => {
      console.log('lang', this.$i18n.locale, lang)
      this.$i18n.locale = lang
      this.$moment.locale(lang)
    });
    // this.$i18n.locale = this.state.language;
    this.getPatient()
    this.getRiskAssessments()
  },
  methods: {
    getPatient(){
      api.ska.getPatientById(this.state.patientId)
        .then((result) => {
          this.patient = result.data
        })
    },

    getRiskAssessments(){
      api.ska.getRiskAssessments(this.state.patientId)
        .then((result) => {
          this.riskAssessments = result.data
          this.riskAssessments.sort((a,b) => { return this.$moment(b.authored, "YYYY-MM-DD").valueOf() - this.$moment(a.authored, "YYYY-MM-DD").valueOf()})
        })
    },

    showExercises(url){
      window.open(url, '_blank').focus();
    },

    getFederation(reasons) {
      let fed = reasons.find(element => {
        if (element.includes('federation')) {
          return true
        }
      });
      return this.fedList[fed]
    },
    getSport(reasons){
      const surveySport = reasons.find(element => {
        if (element.includes('survey-sport')) {
          return true
        }
      });
      if (surveySport) {
        let surveySportCode = surveySport.split('|').pop()

        let sport = skaSports.find(element => {
          if (element.Label === surveySportCode){
            return true
          }
        })

        return sport.Answer || ''
      }
      return '';
    }
  }
}
</script>

<style scoped>

</style>
